import Pagination from 'react-paginate'

import AlertNotFound from 'components/PageProductList/ProductListing/AlertNotFound'
import ProductGrid from 'components/Product/ProductGrid'
import ProductList from 'components/Product/ProductList'
import { type OfferState, useOffer } from 'hooks/useOffer'
import { offersListingProductsToCatalog } from 'models/offers'
import { shallow } from 'zustand/shallow'

import {
  ProductGridLoading,
  ProductListLoading
} from 'components/PageCatalog/Products/Products.loading'
import { ResizeHOC } from 'components/ResizeHOC'
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery'
import { tailwindTheme } from 'theme/tailwindTheme'
import { formatSponsoredProductsToCatalog } from 'utils/formatSponsoredProductsToCatalog'
import * as S from './ProductListing.styles'
import type { ProductListingProps } from './ProductListing.type'

const useOfferSelector = (state: OfferState) => ({
  offer: state.offer,
  params: state.params,
  setParams: state.setParams
})

export default function ProductListing({
  onProductClick,
  productListServer,
  sponsoredProductsData = [],
  view,
  update,
  loadingUpdateOffer,
  loadingOffer,
  emptyOfferMessage,
  cookieIsMobile
}: ProductListingProps) {
  const { offer, params, setParams } = useOffer(useOfferSelector, shallow)

  const isMediaViewTablet = useMediaQuery(
    `(min-width: ${tailwindTheme.screens.tablet})`
  )

  const showEmptyMessage = (!offer || !offer.data.length) && !loadingOffer

  const formatedProductListServer =
    offersListingProductsToCatalog(productListServer)

  const sponsoredProductsId = sponsoredProductsData?.map(
    (product) => product.code
  )

  const dataOfferWithoutSponsored = offer?.data?.filter(
    (product) => !sponsoredProductsId?.includes(product.code)
  )
  const combinedOfferServerProducts = [
    ...formatSponsoredProductsToCatalog(sponsoredProductsData),
    ...offersListingProductsToCatalog(dataOfferWithoutSponsored)
  ]

  function setPage(page: number) {
    setParams({ ...params }, 'pagination', page)
  }

  function showNextPage() {
    return offer?.pagination.current !== offer?.pagination.total
  }

  function showPrevPage() {
    return offer?.pagination.current !== 1
  }

  function renderPagination(isMobile: boolean) {
    return (
      <Pagination
        previousLabel={showPrevPage() && '<'}
        nextLabel={showNextPage() && '>'}
        forcePage={offer?.pagination.current - 1}
        pageCount={offer?.pagination.total}
        pageRangeDisplayed={isMobile ? 4 : 2}
        marginPagesDisplayed={isMobile ? 0 : 5}
        onPageChange={(e: { selected: number }) => setPage(e.selected + 1)}
        containerClassName='pagination'
        activeLinkClassName='active'
        pageLinkClassName='page'
        nextLinkClassName='nextLink'
        previousLinkClassName='prevLink'
        breakLinkClassName='break'
      />
    )
  }

  const renderList = (offerList) => {
    function renderListOffer() {
      return loadingOffer ? (
        <ProductListLoading />
      ) : (
        <S.Listing>
          {offerList?.map((product, index) => {
            product.position = index + 1
            const mapKey = `${product.code}${index}`

            return (
              <ProductList
                key={mapKey}
                product={product}
                update={update?.[product.offer?.code]}
                isLoadingUpdate={loadingUpdateOffer}
                fnOnProductClick={onProductClick}
                offerType={params.type || 'ativas'}
              />
            )
          })}
        </S.Listing>
      )
    }

    function renderGridOffer() {
      return loadingOffer ? (
        <ProductGridLoading />
      ) : (
        <S.Grid id='blocoProdutosListagem'>
          {offerList?.map((product, index) => {
            product.position = index + 1
            const mapKey = `${product.code}${index}`

            return (
              <ProductGrid
                key={mapKey}
                product={product}
                update={update?.[product.offer?.code]}
                isLoadingUpdate={loadingUpdateOffer}
                fnOnProductClick={onProductClick}
                isPageList
                offerType={params.type || 'ativas'}
                cookieIsMobile={cookieIsMobile}
              />
            )
          })}
        </S.Grid>
      )
    }

    return view === 'LISTING' && isMediaViewTablet
      ? renderListOffer()
      : renderGridOffer()
  }

  if (emptyOfferMessage) {
    return <AlertNotFound message={emptyOfferMessage} />
  }

  if (showEmptyMessage) {
    return (
      <>
        <AlertNotFound message='Lamentamos, nenhum produto encontrado com esse critério de pesquisa.' />
      </>
    )
  }

  return (
    <>
      <noscript>{renderList(formatedProductListServer)}</noscript>

      {renderList(combinedOfferServerProducts)}

      {offer?.data && (
        <S.PaginationContainer>
          <S.Pagination id='PaginationOffer'>
            {showPrevPage() && (
              <S.Page onClick={() => setPage(1)}>{'<<'}</S.Page>
            )}
            <ResizeHOC
              minDesktopWidth={tailwindTheme.screens.tablet}
              MobileComponent={() => renderPagination(true)}
              DesktopComponent={() => renderPagination(false)}
              isMobileScreen={cookieIsMobile}
            />
            {showNextPage() && (
              <S.Page onClick={() => setPage(offer?.pagination.total)}>
                {'>>'}
              </S.Page>
            )}
          </S.Pagination>
        </S.PaginationContainer>
      )}
    </>
  )
}
