import { BACKEND_LISTAGEM_OFERTA_URL } from 'settings/kernel'

import { UnexpectedError, getValueOrDefault } from '@k_frontend/core'
import { HttpClient } from 'adapters/httpClient'
import { formatStampsType } from 'utils/formatStampsType'
import { formatProductFriendlyName } from 'utils/friendlyName'
import {
  OfferResponseApi,
  OffersList,
  OffersListParams,
  OffersListResponseApi,
  PaginationResponseApi,
  StampsResponseApi
} from './offersList.types'

export class OffersListService {
  constructor(private readonly httpClient = new HttpClient()) {}

  async getOffersList(params: OffersListParams): Promise<OffersList> {
    const { body, error } =
      await this.httpClient.request<OffersListResponseApi>({
        method: 'get',
        url: `${BACKEND_LISTAGEM_OFERTA_URL}`,
        params: {
          campanha: params.campaign,
          pagina: params.page || 1,
          limite: params.limit || 20,
          marcas: params.brands ? params.brands.join(',') : '',
          ordem: params.order || '',
          valor_min: params.minPrice || '',
          valor_max: params.maxPrice || '',
          estrelas: params.evaluation || '',
          desconto_minimo: params.minDiscount || '',
          desconto_maximo: params.maxDiscount || '',
          dep: params.dep || '',
          sec: params.sec || '',
          vendedor_codigo: params.soldByKabum || '',
          string: params.string || '',
          app: 1,
          separar_campos: params.filterByType || 1,
          tipo: params.type || '',
          selo: params.stamps ? params.stamps.join(',') : '',
          entrega_flash: params.flashShipping || 0,
          frete_gratis: params.freeShipping || 0,
          ...(params.isUserPrime
            ? { usuario_prime: Number(params.isUserPrime) }
            : null)
        }
      })

    if (!body || error) {
      throw new UnexpectedError(`GET OFERTA: ${params.campaign}`)
    }

    return this.formatOffersList(body)
  }

  private getFilters(data: OffersListResponseApi) {
    const { categorias, filtro, selos } = data

    function getHighlightedStampFilters(stampFilters: string[]) {
      if (!stampFilters) return []

      const filteredStampFilters = selos.filter((selo) =>
        stampFilters.includes(selo.nome)
      )

      const formatedStamps = filteredStampFilters.map((selo) => {
        return {
          name: selo.nome,
          title: selo.titulo
        }
      })

      return formatedStamps
    }

    return {
      departments: categorias?.map((categoria) => ({
        name: categoria.nome,
        slug: categoria.amigavel,
        code: categoria.codigo,
        sections: categoria.secs?.map((sec) => ({
          name: sec.nome,
          slug: sec.amigavel,
          code: sec.codigo
        }))
      })),
      brand: filtro?.marcas?.map((item) => ({
        code: String(item.codigo),
        name: item.nome
      })),
      discountRange: {
        min: filtro?.desconto_minimo,
        max: filtro?.desconto_maximo
      },
      priceRange: {
        min: filtro?.valor_minimo,
        max: filtro?.valor_maximo
      },
      highlightedFilters: filtro?.filtro_destaques,
      highlightedStampFilters: getHighlightedStampFilters(
        filtro?.selos_destaques
      ),
      highlightedDiscountPercentages: filtro?.descontos_destaques
    }
  }

  private getPagination(paginacao: PaginationResponseApi) {
    return {
      prev: getValueOrDefault(paginacao?.pagina_anterior, 1),
      current: getValueOrDefault(paginacao?.pagina_atual, 0),
      next: getValueOrDefault(paginacao?.proxima_pagina, 0),
      total: getValueOrDefault(paginacao?.paginas, 0)
    }
  }

  private getOfferInfo(oferta: OfferResponseApi) {
    return {
      name: oferta.nome,
      mainColor: getValueOrDefault(oferta.cor?.primaria, '#FF6500'),
      secondaryColor: getValueOrDefault(oferta.cor?.secundaria, '#FF6500'),
      textColor: getValueOrDefault(oferta.cor?.letra, '#FFFFFF'),
      startDate: oferta.data_inicio,
      endDate: oferta.data_fim,
      title: oferta.title,
      description: oferta.description,
      regulation: oferta.regulamento
    }
  }

  private getProductStamp(stampName: string, selos: StampsResponseApi[]) {
    const findedStamp = selos?.find((selo) => selo.nome === stampName)

    if (findedStamp) {
      return {
        id: findedStamp.codigo,
        name: findedStamp.nome,
        title: findedStamp.titulo,
        backgroundColor: findedStamp.cor_fundo,
        fontColor: findedStamp.cor_fonte,
        type: formatStampsType(findedStamp.tipo)
      }
    }

    return null
  }

  private getProductMapped(data: OffersListResponseApi) {
    const { produtos, oferta, selos } = data

    return produtos?.map((product) => ({
      code: product.codigo,
      available: getValueOrDefault(product.disponibilidade, false),
      offerCode: product.oferta_codigo,
      name: product.produto,
      friendlyName: formatProductFriendlyName(product.produto),
      sellerName: getValueOrDefault(product.vendedor_nome, 'KaBuM!'),
      sellerId: Number(getValueOrDefault(product.vendedor_codigo, 0)),
      category: getValueOrDefault(
        `${product.dep_nome}/${product.sec_nome}`,
        ''
      ),
      manufacturer: getValueOrDefault(product.fabricante_nome, ''),
      image: product.imagem,
      oldPrice: getValueOrDefault(Number(product.vlr_normal), 0),
      price: getValueOrDefault(Number(product.vlr_normal), 0),
      priceWithDiscount: getValueOrDefault(product.vlr_oferta, 0),
      primePrice: getValueOrDefault(Number(product.preco_prime), 0),
      primePriceWithDiscount: getValueOrDefault(
        Number(product.preco_desconto_prime),
        0
      ),
      oldPrimePrice: getValueOrDefault(Number(product.preco_antigo_prime), 0),
      offerDiscount: getValueOrDefault(product.desconto, 0),
      offerDiscountPrime: getValueOrDefault(Number(product.desconto_prime), 0),
      maxInstallment: getValueOrDefault(product.max_parcela, ''),
      maxInstallmentPrime: getValueOrDefault(product.max_parcela_prime, ''),
      quantity: getValueOrDefault(product.quantidade, 0),
      rating: product.tmp_avaliacao_nota,
      ratingCount: getValueOrDefault(product?.tmp_avaliacao_numero, 0),
      hashCode: product.di,
      stamp: this.getProductStamp(product.stamp, selos),
      flags: {
        openbox: getValueOrDefault(!!product.openbox, false),
        freeShipping: getValueOrDefault(!!product.frete_gratis, false),
        freeShippingPrime: getValueOrDefault(
          !!product.frete_gratis_prime,
          false
        ),
        isPreOrder: getValueOrDefault(product?.flags?.is_pre_order, false),
        isFlash: getValueOrDefault(!!product?.entrega_flash || false)
      },
      offerDates: {
        startDate: getValueOrDefault(product.data_ini, 0),
        endDate: getValueOrDefault(product.data_fim, 0)
      },
      colors: {
        main: getValueOrDefault(oferta?.cor?.primaria, ''),
        text: getValueOrDefault(oferta?.cor?.letra, '#FFFFFF')
      },
      offerInfo: {
        mainColor: oferta?.cor?.primaria,
        secondaryColor: oferta?.cor?.secundaria,
        textColor: getValueOrDefault(oferta?.cor?.letra, '#FFFFFF'),
        startDate: getValueOrDefault(product.data_ini, 0),
        endDate: getValueOrDefault(product.data_fim, 0),
        title: getValueOrDefault(oferta?.title, ''),
        description: getValueOrDefault(oferta?.description, '')
      },
      tagCode: getValueOrDefault(product.tag_code, 0),
      offerIdMarketplace: product.id_marketplace,
      isHighlight: getValueOrDefault(product.featured_product, false)
    }))
  }

  private formatOffersList(dataOffersList: OffersListResponseApi): OffersList {
    return (
      dataOffersList && {
        data: this.getProductMapped(dataOffersList),
        needLogin: dataOffersList.logar,
        offerInfo: this.getOfferInfo(dataOffersList.oferta),
        pagination: this.getPagination(dataOffersList.paginacao),
        amount: dataOffersList.quant_ofertas,
        filters: this.getFilters(dataOffersList)
      }
    )
  }
}
