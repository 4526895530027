import { JSX, useEffect, useState } from 'react'

import { dataLayerEventPromotionView } from '@k_frontend/core'
import Button from 'components/Button'
import OfferTimer from 'components/OfferTimer'

import { ResizeHOC } from 'components/ResizeHOC'
import { tailwindTheme } from 'theme/tailwindTheme'
import OfferModalRules from './OfferModalRules'
import * as S from './OffersListingHeader.styles'
import { OffersListingHeaderProps } from './OffersListingHeader.types'
import { TIMER_CONFIG, TIMER_CONFIG_MOBILE } from './timerConfig.constants'

export default function OffersListingHeader({
  cookieIsMobile,
  offerInfo,
  offerBanner
}: OffersListingHeaderProps): JSX.Element {
  const [modalVisible, setModalVisible] = useState(false)
  const showOfferTimer =
    offerInfo?.name?.toLowerCase() !== 'oferta do dia' &&
    offerInfo?.name?.toLowerCase() !== 'preço garantido black friday' &&
    offerInfo?.name?.toLowerCase() !== 'ninja indica'

  function getTimerConfig(isMobile: boolean, textColor: string) {
    const configByIsMobile = {
      true: TIMER_CONFIG_MOBILE,
      false: TIMER_CONFIG
    }

    const configDefault = configByIsMobile[String(isMobile)]

    return { ...configDefault, text: { ...configDefault.text, textColor } }
  }

  function renderBannerOffer(isMobile: boolean) {
    return (
      <S.FigureOfferImage>
        <S.OfferImage
          id='bannerOfertaListagem'
          src={isMobile ? offerBanner?.banner : offerBanner?.bannerMobile}
        />
      </S.FigureOfferImage>
    )
  }

  function renderOfferTimer(isMobile: boolean) {
    return (
      <>
        <OfferTimer
          config={getTimerConfig(isMobile, offerInfo?.textColor)}
          offerInfo={offerInfo}
          showIcon={false}
        />
      </>
    )
  }

  useEffect(() => {
    const { id, creative } = offerBanner
    const datalayerTitle = `Banner oferta ${offerInfo?.name}`
    const bannerFormatted = {
      id,
      name: datalayerTitle,
      creative,
      position: 'tema_listagem_1',
      index: 1
    }

    dataLayerEventPromotionView([bannerFormatted])
  }, [])

  return (
    <>
      <ResizeHOC
        minDesktopWidth={tailwindTheme.screens.tablet}
        MobileComponent={() => renderBannerOffer(false)}
        DesktopComponent={() => renderBannerOffer(true)}
        isMobileScreen={cookieIsMobile}
      />
      <S.Container
        id='blocoOfertaListagem'
        mainColor={offerInfo?.mainColor}
        textColor={offerInfo?.textColor}
      >
        <S.OfferTitle textColor={offerInfo?.textColor}>
          {offerInfo?.name}
        </S.OfferTitle>
        <Button
          id='botaoVerRegulamentoOferta'
          onClick={() => setModalVisible(true)}
        >
          Consulte condições
        </Button>
        {showOfferTimer && (
          <S.OfferCountdown>
            <S.CountdownLabel textColor={offerInfo?.textColor}>
              TERMINA EM:
            </S.CountdownLabel>
            <ResizeHOC
              minDesktopWidth={tailwindTheme.screens.tablet}
              MobileComponent={() => renderOfferTimer(false)}
              DesktopComponent={() => renderOfferTimer(true)}
              isMobileScreen={cookieIsMobile}
            />
          </S.OfferCountdown>
        )}
      </S.Container>

      <OfferModalRules
        isOpen={modalVisible}
        handleClose={() => setModalVisible(false)}
        regulation={offerInfo?.regulation}
      />
    </>
  )
}
