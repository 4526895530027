import { useEffect, useMemo, useRef, useState } from 'react'
import { type Query, useQueries } from 'react-query'

import {
  NotFoundError,
  type TypeUserState,
  dataLayerEventListing,
  dataLayerEventProductClick,
  hasSponsoredProductAtLocalStorage,
  sendBeaconData,
  useUser
} from '@k_frontend/core'
import { Col, Container, Row } from '@k_frontend/ui'
import FooterLoginNotification from 'components/FooterLoginNotification'
import NotFoundPage from 'components/NotFoundPage'
import OffersListingHeader from 'components/OffersListingHeader'
import FilteringTypes from 'components/PageProductList/FilteringTypes'
import ProductListing from 'components/PageProductList/ProductListing'
import Breadcrumb from 'components/PageProductList/ProductListing/Breadcrumb'
import type { UpdateOfferModel } from 'domain/models'
import {
  makeRemoteLoadTimeStampFactory,
  makeRemoteLoadUpdateOfferFactory
} from 'factories'
import { useListingView } from 'hooks/useListingView'
import {
  type OfferState,
  type OfferStateParams,
  useOffer
} from 'hooks/useOffer'
import useOnScreen from 'hooks/useOnScreen'
import { useTimestamp } from 'hooks/useTimestamp'
import type { TimestampState } from 'hooks/useTimestamp/types'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { getBannerOffer } from 'services/banner/banner'
import { getParamsFromQueryString } from 'services/listing'
import { OffersList, OffersListService } from 'services/offersList'
import { SITE_TAG_DESCRIPTION, SITE_TAG_TITLE } from 'settings/seo'
import type { Product } from 'types/catalog'
import { clearObject } from 'utils/functions'
import { shallow } from 'zustand/shallow'

import PageHead from 'components/PageHead'
import { SponsoredProductsService } from 'services/sponsoredProducts'
import { formatSendBeaconData } from 'utils/formatSendBeaconData'
import { formatSponsoredProductsHeadersParams } from 'utils/formatSponsoredProductsHeadersParams'
import { getSponsoredProductsVariation } from 'utils/sponsoredProductsVariation'
import * as S from './ofertas.styles'
import { OFFER_TYPE, type PageOfertaProps } from './ofertas.types'

const AsideFilterList = dynamic(
  () => import('components/PageProductList/AsideFilterList'),
  {
    ssr: false
  }
)
const OrderingProducts = dynamic(
  () => import('components/PageProductList/OrderingProducts'),
  {
    ssr: false
  }
)
const FilterTags = dynamic(
  () => import('components/PageProductList/FilterTags'),
  {
    ssr: false
  }
)

const useUserSelector = (state: TypeUserState) => ({
  isLogged: state.isLoggedIn,
  isUserPrime: state.isPrime,
  clientId: state.clientId,
  session: state.session,
  validSession: state.validSession
})

const useTimeStampSelector = (state: TimestampState) => ({
  setTimeStamp: state.setTimestamp,
  setLoadingTimeStamp: state.setLoading
})

const useOfferSelector = (state: OfferState) => ({
  filterPressed: state.filterPressed,
  init: state.init,
  params: state.params,
  setParams: state.setParams,
  setOffer: state.setOffer,
  offer: state.offer,
  stockUpdate: state.stockUpdate,
  setStockUpdate: state.setStockUpdate
})

export default function Oferta({
  dataOfferServer,
  offerName,
  cookieIsMobile
}: PageOfertaProps) {
  const sponsoredProductsService = new SponsoredProductsService()
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const [isSponsoredProductsEnabled, setIsSponsoredProductsEnabled] =
    useState<boolean>(false)
  const [loadingFeatureFlag, setLoadingFeatureFlag] = useState<boolean>(true)

  const top = useRef()
  const onScreen = useOnScreen(top, null)
  const {
    filterPressed,
    init,
    params,
    setParams,
    setOffer,
    offer,
    stockUpdate,
    setStockUpdate
  } = useOffer(useOfferSelector, shallow)
  const { view, setView } = useListingView()
  const { isLogged, isUserPrime, clientId, session, validSession } = useUser(
    useUserSelector,
    shallow
  )
  const { setTimeStamp, setLoadingTimeStamp } =
    useTimestamp(useTimeStampSelector)
  const router = useRouter()
  const offersListService = new OffersListService()

  const beaconData = formatSendBeaconData(clientId, session)

  const { headers, params: sponsoredProductsParams } =
    formatSponsoredProductsHeadersParams({
      clientId,
      session,
      query: offerName,
      context: 'offer'
    })

  const isActiveOfferType = params?.type === 'ativas' || !params?.type
  const isScheduleOffer = params?.type === 'agendadas'

  const hasSponsoredProductsInOffersByParams = {
    page: 1,
    type: 'ativas'
  }

  const evenSponsoredProductInOffersByParams = [
    'campaign',
    'isUserPrime',
    'order',
    'limit'
  ]

  const breadcrumbLabels = [
    {
      code: 0,
      name: offerName,
      slug: `ofertas/${offerName}`
    }
  ]
  const datalayer = {
    listingData: {
      listagem: [],
      breadcrumb: [],
      type: 'offer',
      pageList: `oferta-${offerName}`
    }
  }

  const [
    { isLoading: loadingOffer, isFetching: isFetchingOffer },
    {
      data: dataSponsoredProducts,
      isLoading: loadingSponsoredProducts,
      isFetching: isFetchingSponsoredProducts
    },
    { data: dataUpdateOffer, isLoading: loadingUpdateOffer, error },
    { data: offerBanner, isLoading: loadingBanner },
    { refetch: refetchTimestamp }
  ] = useQueries([
    {
      queryKey: ['getOffersList', params],
      queryFn: () => offersListService.getOffersList(params),
      onSuccess: (data) => {
        if (stockUpdate && (isActiveOfferType || isScheduleOffer))
          removeProductsOutOfStock(stockUpdate, data)
        else setOffer(data)

        setUrlParams()
        refetchTimestamp()
      },
      initialData: dataOfferServer,
      refetchOnMount: !firstRender
    },
    {
      queryKey: ['getSponsoredProducts', params, isSponsoredProductsEnabled],
      queryFn: () =>
        sponsoredProductsService.getSponsoredProducts(
          headers,
          sponsoredProductsParams
        ),
      enabled:
        hasSponsoredProducts(hasSponsoredProductsInOffersByParams, params) &&
        isSponsoredProductsEnabled,
      retry: false
    },
    {
      queryKey: ['getUpdateOffer'],
      queryFn: () => makeRemoteLoadUpdateOfferFactory().load(offerName),
      onSuccess: (data: UpdateOfferModel.Model) => {
        if (isActiveOfferType || isScheduleOffer) {
          removeProductsOutOfStock(data, offer)
        }
        setStockUpdate(data)
      },
      refetchInterval: (data: UpdateOfferModel.Model, query: Query) =>
        !data && query.state.error instanceof NotFoundError ? false : 5000
    },
    {
      queryKey: ['getBannerOffer'],
      queryFn: () => getBannerOffer(offerName)
    },
    {
      queryKey: ['getTimeStamp'],
      queryFn: () => makeRemoteLoadTimeStampFactory().load(),
      onSuccess: (data: number) => {
        setTimeStamp(data)
        setLoadingTimeStamp(false)
      },
      refetchOnWindowFocus: true
    }
  ])

  const sponsoredProductsLength = dataSponsoredProducts?.data?.length

  const isLoadingdOffers =
    loadingOffer ||
    isLoadingSponsoredProducts() ||
    isFetchingOffer ||
    firstRender

  const selectedBrands = offer?.filters?.brand?.filter((brand) =>
    params?.brands?.includes(brand.code)
  )
  const selectedStamps = offer?.filters?.highlightedStampFilters?.filter(
    (stamp) => params?.stamps?.includes(stamp.name)
  )
  const selectedDepartment = offer?.filters?.departments?.find(
    (department) => department.slug === params?.dep
  )
  const selectedSection = selectedDepartment?.sections?.find(
    (section) => section.slug === params?.sec
  )
  const selectedPrices = params?.minPrice && params?.maxPrice
  const selectedDiscounts = params?.minDiscount && params?.maxDiscount

  const hasFilterSelect =
    selectedBrands?.length > 0 ||
    selectedStamps?.length > 0 ||
    !!selectedDepartment ||
    !!selectedSection ||
    !!selectedPrices ||
    !!selectedDiscounts ||
    !!params?.evaluation ||
    !!params?.soldByKabum ||
    !!params?.freeShipping ||
    !!params?.flashShipping
  const hasFilterTypes = offerName !== 'ofertadodia'

  function removeProductsOutOfStock(
    dataUpdateProducts: UpdateOfferModel.Model,
    dataOffer: OffersList
  ) {
    let tempProductsOutOfStock = dataOffer.data

    for (const [key, value] of Object.entries(dataUpdateProducts)) {
      if (value < 1) {
        tempProductsOutOfStock = tempProductsOutOfStock.filter(
          (item) => item.offerCode !== Number(key)
        )
      }
    }

    if (tempProductsOutOfStock.length !== dataOffer.data.length) {
      setOffer({ ...dataOffer, data: tempProductsOutOfStock })
    } else {
      setOffer(dataOffer)
    }
  }

  function hasDefaultValues(
    params: OfferStateParams,
    hasSponsoredProductsInOffersByParams: {
      page: number
      type: string
    }
  ): boolean {
    return Object.entries(params).every(([key, value]) => {
      if (evenSponsoredProductInOffersByParams.includes(key)) return true
      return hasSponsoredProductsInOffersByParams[key] === value
    })
  }

  function hasSponsoredProducts(
    hasSponsoredProductsInOffersByParams: {
      page: number
      type: string
    },
    params?: OfferStateParams
  ): boolean {
    if (params === undefined) return false

    const allowedKeys = [
      ...Object.keys(hasSponsoredProductsInOffersByParams),
      ...evenSponsoredProductInOffersByParams
    ]

    const hasOnlyAllowedKeys = Object.keys(params).every((key) =>
      allowedKeys.includes(key)
    )

    if (!hasOnlyAllowedKeys) return false

    return hasDefaultValues(params, hasSponsoredProductsInOffersByParams)
  }

  function dataLayerProductClick(product: Product) {
    const productFormatted = [
      {
        name: product.name,
        id: product.code.toString(),
        price: product.price,
        brand: product.manufacturer.name,
        category: product.category,
        position: product.position,
        dimension20: product.sellerId,
        dimension21: product.sellerName,
        dimension01: hasSponsoredProductAtLocalStorage(product.code)
      }
    ]
    dataLayerEventProductClick({
      products: productFormatted,
      list: `oferta-${offerName}`
    })

    if (product?.newtail) {
      sendBeaconData(product.newtail.clickUrl, beaconData)
    }
  }

  function setUrlParams() {
    if (!onScreen) window.scrollTo({ top: 0, behavior: 'smooth' })

    const { query } = router

    const urlParams = {
      pagina: params.page,
      marcas: params.brands,
      limite: params.limit,
      ordem: params.order,
      min: params.minPrice,
      max: params.maxPrice,
      estrelas: params.evaluation,
      desconto_minimo: params.minDiscount,
      desconto_maximo: params.maxDiscount,
      dep: params.dep,
      sec: params.sec,
      string: params.string,
      vendedor_codigo: params.soldByKabum,
      tipo: params.type,
      frete_gratis: params.freeShipping,
      entrega_flash: params.flashShipping,
      selo: params.stamps
    }

    const campaignParams = {
      utm_source: query?.utm_source,
      utm_medium: query?.utm_medium,
      utm_campaign: query?.utm_campaign,
      utm_term: query?.utm_term,
      utm_content: query?.utm_content
    }

    const isBackButton = filterPressed === 'back'
    const newUrl = decodeURIComponent(
      `${window.location.pathname}?` +
        new URLSearchParams(clearObject({ ...urlParams, ...campaignParams }))
    )
    if (isBackButton) {
      window.history.replaceState({}, '', newUrl)
    } else {
      window.history.pushState({}, '', newUrl)
    }
  }

  function getEmptyOfferMessage() {
    if (
      !hasFilterSelect &&
      !offer?.data?.length &&
      !loadingOffer &&
      !isFetchingOffer
    ) {
      switch (params?.type) {
        case OFFER_TYPE.SCHEDULED:
          return 'No momento não temos ofertas agendadas para esta campanha! Aproveite e garanta as ofertas ativas!'
        case OFFER_TYPE.CLOSED:
          return 'No momento não temos ofertas encerradas para esta campanha! Corra e aproveite as ofertas ativas!'
        default:
          return 'Aguarde! O Ninja está preparando novas ofertas para você! Consulte Proximas Ofertas desta campanha.'
      }
    }

    return null
  }

  function getQueryValue(queryValue: string | string[], offerValue: number) {
    if (Number(queryValue) > offerValue) return String(offerValue)

    return queryValue
  }

  function handleOpenFilters(e) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setIsFiltersOpen(e)
  }

  const renderOffersListingHeader = useMemo(
    () =>
      loadingBanner && firstRender && !dataOfferServer.offerInfo ? (
        <div className={S.skeleton({ role: 'banner' })} />
      ) : (
        <>
          {offerBanner && dataOfferServer.offerInfo && (
            <OffersListingHeader
              offerInfo={dataOfferServer.offerInfo}
              offerBanner={offerBanner}
              cookieIsMobile={cookieIsMobile}
            />
          )}
        </>
      ),
    [offerBanner, dataOfferServer.offerInfo]
  )

  const renderAsideFilterList = useMemo(
    () =>
      firstRender ? (
        <div className={S.skeleton({ role: 'asideFilter' })} />
      ) : (
        <AsideFilterList
          isFiltersOpen={isFiltersOpen}
          setIsFiltersOpen={setIsFiltersOpen}
          offerFilters={offer?.filters}
          cookieIsMobile={cookieIsMobile}
        />
      ),
    [firstRender, isFiltersOpen]
  )

  async function verifySponsoredProductsFeatureEnabled(session: string) {
    const sponsoredProductsVariation =
      await getSponsoredProductsVariation(session)
    setIsSponsoredProductsEnabled(sponsoredProductsVariation)
    setLoadingFeatureFlag(false)
  }

  function isLoadingSponsoredProducts() {
    return loadingFeatureFlag || loadingSponsoredProducts
  }

  useEffect(() => {
    verifySponsoredProductsFeatureEnabled(session)
  }, [validSession, session])

  useEffect(() => {
    if (firstRender) setFirstRender(false)

    const { query } = router

    const params = {
      campaign: offerName,
      page: Number(query.pagina) || 1,
      brands: query.marcas && String(query.marcas)?.split(','),
      limit: cookieIsMobile ? 10 : 20,
      order: query.ordem,
      minPrice: getQueryValue(
        query.min,
        dataOfferServer?.filters.priceRange.min
      ),
      maxPrice: getQueryValue(
        query.max,
        dataOfferServer?.filters.priceRange.max
      ),
      evaluation: query.estrelas,
      minDiscount: getQueryValue(
        query.desconto_minimo,
        dataOfferServer?.filters.discountRange.min
      ),
      maxDiscount: getQueryValue(
        query.desconto_maximo,
        dataOfferServer?.filters.discountRange.max
      ),
      dep: query.dep,
      sec: query.sec,
      string: query.string,
      soldByKabum: query.vendedor_codigo,
      type: query.tipo,
      freeShipping: query.frete_gratis,
      stamps: query.selo && String(query.selo)?.split(','),
      isUserPrime
    } as OfferStateParams

    init({
      params: clearObject(params),
      offer: dataOfferServer
    })

    window.onpopstate = () => {
      const queryString = getParamsFromQueryString(
        window.location.search
      ) as Record<string, string>

      setParams(
        {
          ...params,
          page: Number(queryString.pagina || 1)
        },
        'back'
      )
    }
  }, [dataOfferServer.offerInfo])

  useEffect(() => {
    if (
      offer?.data.length &&
      !isFetchingOffer &&
      !isFetchingSponsoredProducts
    ) {
      const datalayerWithOffers = {
        ...datalayer,
        listingData: {
          ...datalayer.listingData,
          listagem: [...(dataSponsoredProducts?.data || []), ...offer.data]
        }
      }

      dataLayerEventListing(datalayerWithOffers)
    }

    if (dataSponsoredProducts?.data.length) {
      dataSponsoredProducts.data.map((SponsoredProduct) =>
        sendBeaconData(SponsoredProduct.newtail.impressionUrl, beaconData)
      )
    }
  }, [!isFetchingOffer && !isFetchingSponsoredProducts])

  if (error instanceof NotFoundError && !dataOfferServer?.data?.length) {
    return (
      <S.NotFoundContainer>
        <NotFoundPage />
      </S.NotFoundContainer>
    )
  }

  return (
    <S.Body>
      <PageHead
        metaDescription={
          dataOfferServer?.offerInfo?.description || SITE_TAG_DESCRIPTION
        }
        metaTitle={dataOfferServer?.offerInfo?.title || SITE_TAG_TITLE}
        hasCanonical
      />
      <Script id='SmartHint_PageType' type='application/javascript'>
        const SmartHint_PageType = `other`
      </Script>
      <div className='hidden tablet:block'>
        <Container>
          <Breadcrumb labels={breadcrumbLabels} />
        </Container>
      </div>
      <Container
        sm={{ overflowLeft: true, overflowRight: true }}
        md={{ overflowLeft: true, overflowRight: true }}
      >
        <section className='aspect-[360/246] tablet:aspect-[600/174] desktop:aspect-[960/258] desktop:mb-32 min[1441px]:mb-0 desktopLarge:aspect-[1377/339]'>
          {renderOffersListingHeader}
        </section>
      </Container>
      <Container>
        <Row>
          <Col sm={4} md={6} lg={3} xl={2}>
            {renderAsideFilterList}
          </Col>
          <S.ColCustomListing sm={4} md={6} lg={9} xl={10}>
            <S.ContentHeader
              ref={top}
              showTags={hasFilterSelect}
              showFilterTypes={hasFilterTypes}
            >
              {firstRender ? (
                <div className={S.skeleton({ role: 'orderingProducts' })} />
              ) : (
                <>
                  {hasFilterTypes && <FilteringTypes />}

                  <OrderingProducts
                    view={view}
                    setView={setView}
                    setIsFiltersOpen={handleOpenFilters}
                    breadcrumbLabels={breadcrumbLabels}
                    isLoadingOffers={isLoadingdOffers}
                    sponsoredProductsLength={sponsoredProductsLength}
                    cookieIsMobile={cookieIsMobile}
                  />
                  {hasFilterSelect && <FilterTags />}
                </>
              )}
            </S.ContentHeader>
            <ProductListing
              onProductClick={dataLayerProductClick}
              productListServer={dataOfferServer?.data}
              sponsoredProductsData={dataSponsoredProducts?.data}
              view={view}
              loadingUpdateOffer={loadingUpdateOffer}
              loadingOffer={isLoadingdOffers}
              update={dataUpdateOffer}
              emptyOfferMessage={getEmptyOfferMessage()}
              cookieIsMobile={cookieIsMobile}
            />
          </S.ColCustomListing>
        </Row>
      </Container>
      {!isLogged && offer?.needLogin === '1' && (
        <FooterLoginNotification
          message={
            'ATENÇÃO NINJA! É NECESSÁRIO FAZER LOGIN PARA APROVEITAR ESSAS OFERTAS'
          }
        />
      )}
    </S.Body>
  )
}

export async function getServerSideProps({ query, req, res }) {
  const offersListService = new OffersListService()
  const isUserPrime = req.cookies?.CP_1286400 === 'true'

  try {
    const { offerName } = query

    if (!offerName) {
      throw new Error()
    }

    const params = {
      campaign: offerName,
      page: query.pagina,
      brands: [query.marcas],
      limit: query.limit,
      order: query.order,
      minPrice: query.min,
      maxPrice: query.max,
      evaluation: query.estrelas,
      minDiscount: query.desconto_minimo,
      maxDiscount: query.desconto_maximo,
      dep: query.dep,
      sec: query.sec,
      string: query.string,
      soldByKabum: query.vendedor_codigo,
      type: query.tipo,
      freeShipping: query.frete_gratis,
      stamps: query.selo && String(query.selo)?.split(','),
      isUserPrime
    }

    let dataOfferServer: OffersList

    try {
      dataOfferServer = await offersListService.getOffersList(params)
    } catch (e) {
      console.error(JSON.stringify(e))
    }

    if (!Object.keys(dataOfferServer?.offerInfo).length) throw new Error()

    res.setHeader('Cache-Control', 'max-age=60')
    return {
      props: {
        dataOfferServer,
        offerName,
        cookieIsMobile: req.cookies?.isMobile === 'true'
      }
    }
  } catch (_e) {
    return {
      notFound: true
    }
  }
}
